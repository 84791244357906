import { render, h } from "preact";
import FullUI from "./full-ui.component";
import Popup from "./popup.component";

if (window.customElements) {
  window.customElements.define("test-ui-full", preactCustomElement(FullUI, ["show-when-local-storage"]));
  window.customElements.define("test-ui-popup", preactCustomElement(Popup));
}

function preactCustomElement(Comp, observedAttributes = []) {
  return class PreactCustomElement extends HTMLElement {
    connectedCallback() {
      this.renderWithPreact();
    }
    disconnectedCallback() {
      render(null, this);
      this.renderedEl = null;
    }
    static get observedAttributes() {
      return observedAttributes;
    }
    attributeChangedCallback() {
      this.renderWithPreact();
    }
    renderWithPreact() {
      this.shadow = this.attachShadow({ mode: "open" });
      this.shadowRoot.innerHTML = `
        <style>
        .tablink {
          background-color: #555;
          color: white;
          float: left;
          border: none;
          outline: none;
          cursor: pointer;
          padding: 14px 16px;
          font-size: 17px;
          width: 25%;
        }
        
        .tablink:hover {
          background-color: #777;
        }
        
        /* Style the tab content (and add height:100% for full page content) */
        .tabcontent {
          color: white;
          display: none;
          padding: 100px 20px;
          height: 100%;
        }
        
        .tui-unstyled {
          border: none;
          padding: 0;
          width: auto;
          overflow: visible;
          background: transparent;
          color: inherit;
          font-size: xx-large;
          line-height: normal;
          cursor: pointer;
          -webkit-font-smoothing: inherit;
          -moz-osx-font-smoothing: inherit;
          -webkit-appearance: none;
        }
        
        .tui-unstyled::-moz-focus-inner {
          border: 0;
          padding: 0;
        }
        
        .tui-trigger {
          position: fixed;
          bottom: 0;
          left: 0;
          margin: 10px;
          border-radius: 5px;
          background-color: lightcyan;
          height: 50px;
          width: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          font-family: sans-serif;
          z-index: 10500;
        }

        .tui-test-integration-button {
          width: 100%;
          border: 0.0625rem solid #f1f1f1;
          color: black !important;
          box-sizing: border-box;
          display: inline-flex;
          justify-content: center;
          padding: 0.4375rem 0.9375rem;
          text-decoration: none;
          border-radius: 5px;
          background-color: #f1f1f1;
          margin-top: 10px;
          margin-bottom: 30px;
        }
        
        .tui-popup {
          box-sizing: border-box;
          position: fixed;
          bottom: 0;
          left: 0;
          max-width: 700px;
          width: 100%;
          min-height: 350px;
          z-index: 10000;
          background-color: #68BEE7;
          color: white;
          font-family: sans-serif;
          padding-left: 28px;
          padding-right: 28px;
          overflow-y: auto;
        }
        
        .tui-popup a:visited,
        .tui-popup a {
          color: white;
        }
        
        .tui-popup .tui-module-dialog {
          left: calc(50% - 200px);
        }
        
        .tui-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 10px;
        }
        
        .tui-test-area {
          background-color: #2E5685;
          padding: 20px 20px;
          box-sizing: border-box;
          border-radius: 0px 10px 10px 10px;
        }
        
        .tui-test-patient-context {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          max-width: 600px;
        }
        
        .tui-notification-input {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        }

        .tui-global-dialog-input-container, .tui-toast-notification-input {
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          select {
            width: 100px;
          }

          input {
            width: 150px !important;
          }

          button {
            min-width: 150px;
          }
        }

        .tui-toast-notification-input {
          input {
            width: 250px !important;
          }
        }
        
        .tui-count-event-input {
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          select {
            width: 180px;
          }
        }
        
        input[type=text], input[type=number], select {
          width: 250px;
          padding: 0.4375rem 0.9375rem;
          margin-top: 12px;
          margin-right: 12px;
          border: 1px solid #ccc;
          border-radius: 4px;
          box-sizing: border-box;
        }
        
        .tui-test-button {
          border: 0.0625rem solid #3b78bd;
          color: black;
          align-items: center;
          border-radius: 0.1875rem;
          box-sizing: border-box;
          cursor: pointer;
          display: inline-flex;
          font-family: inherit;
          font-size: .875rem;
          font-weight: 300;
          justify-content: center;
          line-height: 1rem;
          margin-top: 12px;
          margin-right: 12px;
          min-width: 8.725rem;
          padding: 0.4375rem 0.9375rem;
          text-decoration: none;
          vertical-align: middle;
          border-radius: 5px
        }

        .tui-test-checkbox-label {
          color: white;
          box-sizing: border-box;
          cursor: pointer;
          font-family: inherit;
          font-size: .875rem;
          font-weight: 300;
          line-height: 1rem;
          min-width: 1.725rem;
          text-decoration: none;
          vertical-align: middle;
          border-radius: 5px
        }

        .tui-test-checkbox {
          padding: 1.4375rem 0.9375rem;
          vertical-align: middle;
        }
        
        .tui-test-button:hover{
          border-color: rgba(255,255,255,1);
        }
        
        .tui-test-button:active{
          box-shadow:inset 0 0.6em 2em -0.3em rgba(0,0,0,0.15),inset 0 0 0em 0.05em rgba(255,255,255,0.12);
        }
        
        /* Style the tab */
        .tui-tab {
          overflow: hidden;
          background-color: inherit;
        }
        
        /* Style the buttons that are used to open the tab content */
        .tui-tab button {
          background-color: #f1f1f1;
          float: left;
          border: none;
          outline: none;
          cursor: pointer;
          padding: 14px 16px;
          transition: 0.3s;
          font-family: sans-serif;
          font-size: .875rem;
          font-weight: 300;
          border-radius: 10px 10px 0px 0px;
        }

        .tui-tablinks {
          max-width: 100px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        @media screen and (max-width: 670px) {
          .tui-tablinks {
            max-width: 50px;
          }
        }

        @media screen and (max-width: 410px) {
          .tui-tablinks {
            max-width: 30px;
            padding: 14px 10px !important;
          }
        }
        
        .tui-tab .selected {
          background-color: #2E5685;
          color: white;  
        }
        /* Change background color of buttons on hover */
        .tui-tab button:hover {
          background-color: rgb(94, 147, 207);
        }
        
        /* Change background color of buttons on hover */
        .tui-tab button:focus {
          background-color: #2E5685;
          color: white;
        }

        /* Create an active/current tablink class */
        .tui-tab button.active {
          background-color: #2E5685;
        }

        .tui-simulate-multiple-emis-web-instances label,
        .tui-simulate-multiple-emis-web-instances input {
          cursor: pointer;
          line-height: 20px;
          vertical-align: middle;
        }

        .tui-simulate-multiple-emis-web-instances input {
          margin: 0 0 0 5px;
        }
        </style>`;
      this.renderedEl = render(h(Comp, { customElement: this }), this.shadow, this.renderedEl);
    }
  };
}
