import { h } from "preact";
import { useState } from "preact/hooks";

const ExampleNotificationData = {
  app: "chat",
  title: "URGENT!!",
  content: "ROBINS, Diana (Ms) has sent an urgent message",
  priority: "1",
  from: "ROBINS, Diana (Ms)",
  redirect: "staff",
  header: "Urgent",
  action: "Read",
  avatar: "DB",
  force: "0",
  imageName: "emis_1.jpg",
  imageUrl: "https://unsplash.it/64?image=669",
  ts: "2017-04-15T19:45:00Z"
};

const ExampleClientData = {
  content: "This is a test notification",
  duration: 5000,
  notificationType: "info",
  sensitivity: "foreground"
};

const TestNofications = () => {
  const [type, setType] = useState("User");
  const [data, setData] = useState("");

  return (
    <div className="tui-test-area">
      <div className="tui-notification-input">
        <input
          data-testid="tui-notification-json-input"
          className="tui-test-notification-json"
          aria-label="Notification JSON"
          placeholder="Notification JSON"
          type="text"
          value={data}
          onInput={(evt) => setData(evt.target.value)}
        />
        <select data-testid="tui-notification-type-select" value={type} onChange={(evt) => setType(evt.target.value)}>
          <option value="User">User</option>
          <option value="Organisation">Organisation</option>
          <option value="ThirdParty">Third Party</option>
          <option value="Platform">Platform Wide</option>
        </select>
      </div>
      <button
        className="tui-test-button"
        data-testid="tui-notification-receive-button"
        onClick={() => {
          const notification = JSON.parse(data);
          const event = new CustomEvent("test-notification-receive", {
            detail: {
              notification: {
                data: notification,
                type
              }
            }
          });
          document.dispatchEvent(event);
        }}
      >
        Receive
      </button>
      <button
        className="tui-test-button"
        data-testid="tui-notification-example-button"
        onClick={() => {
          setData(JSON.stringify(type === "Client" ? ExampleClientData : ExampleNotificationData));
          setType(type === "Client" ? type : "User");
        }}
      >
        Example
      </button>
    </div>
  );
};

export default TestNofications;
